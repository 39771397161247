@import "./mixin";

@font-face {
  font-family: "SUIT-Regular";
  src: url("../asset/font/SpoqaHanSansNeo-Medium.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  color: #fff;
}

* {
  font-family: "SUIT-Regular", sans-serif;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

body {
  // overflow: scroll;
  overflow-x: hidden;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 6px;
  display: block;
}

::-webkit-scrollbar-track {
  background: #252525;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgb(98, 98, 98);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

button {
  cursor: pointer;
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
}
