.NewsCheckModal {
    position: absolute;
    top: 0px;
    left: 0;
    height: 120vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 9992;
    display: flex;
    align-items: center;
    justify-content: center;

    .NewsCheck {
        width: 701px;
        height: 417px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding-left: 52px;
        border-radius: 15px;
        position: relative;

        .ModalDragon {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .Error {
            margin-top: 53px;
            font-size: 50px;
            font-weight: bold;
            color: #ff4343;
            margin-bottom: 41px;
        }

        .OneMore {
            font-size: 30px;
            font-weight: bold;
            color: #515151;
            margin-bottom: 13px;
        }

        .Text {
            font-size: 18px;
            font-weight: bold;
            color: #949494;
            margin-bottom: 75px;
        }

        .CheckBtn {
            display: flex;

            .Ok {
                width: 136px;
                height: 45px;
                border-radius: 15px;
                background-color: #ffb800;
                color: #fff;
                font-size: 28px;
                margin-right: 46px;
                font-weight: bold;
            }

            .No {
                width: 136px;
                height: 45px;
                border-radius: 15px;
                background-color: #FF7777;
                color: #fff;
                font-size: 28px;
                font-weight: bold;
            }
        }
    }
}