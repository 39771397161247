@import "../../mixin";

.DetailDevelop{
    padding-bottom: 280px;
    padding-top:188px;
    padding-left: 257px;
    padding-right: 257px;
    .SubTitle{
        margin-top: 160px!important;
        margin-bottom: 60px!important;
    }
}

@include tablet{
    .DetailDevelop{
        padding-left: 110px;
        padding-right: 110px;
    }
}

@include mobile{
    .DetailDevelop{
        padding-top:110px;
        padding-bottom: 164px;
        padding-left: 30px;
        padding-right: 30px;
        .SubTitle{
            margin-top: 90px!important;
        }
    }
}