@import "../../mixin";

.Procedure {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Explanation {
        font-size: 20px;
        margin-bottom: 60px;
    }

    .BigText {
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 106px;
    }

    .SubText {
        margin-top: 106px;
        font-size: 20px;
        text-align: center;
        line-height: 180%;
    }
}

@include laptop {
    .Procedure {
        height: 130vh;

        .Explanation {
            margin-bottom: 50px;
        }

        .BigText {
            margin-bottom: 80px;
            font-size: 2.5rem;
        }

        .SubText {
            margin-top: 80px;
        }
    }
}

@include tablet {
    .Procedure {
        .BigText {
            font-size: 3.15rem;
            zoom: 50%;
        }
        .SolutionImg{
            width: 80%;
        }
    }
}

@include mobile {
    .Procedure {
        margin-top: 100px;
        margin-bottom: 200px;
        height: auto;
        .Explanation {
            display: none;
        }

        .BigText {
            // margin-top: 130px;
            font-size: 6.5vw;
            zoom: 50%;
            margin-bottom: 60px;
        }

        .SolutionImg{
            // content: url('../../../asset/img/Service/Procedure/SolutionProceduremobile.png');
            width: 60%;
        }

        .SubText {
            font-size: 12px;
            margin-top: 60px;
            margin-bottom: 130px;
        }
        img {
            width: 350px;
        }
    }
}