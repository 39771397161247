@import "../mixin";

$footer-color: #252525;
$footer-font-color: #ffffff;
$company-info-color: #dfdfdf;
$sitemap-title-color: #555555;
$familysite-font-color: #343434;
$sitemap-page-color: rgba(195.38, 195.38, 195.38, 0.99);
$irpr-font-color: #909090;
$familysite-li-border-color: #eee;

.Footer {
  width: 100vw;
  background-color: $footer-color;
  padding-top: 85px;
  padding-bottom: 85px;
  padding-left: 260px;
  padding-right: 261px;

  .MainSection {
    display: flex;
    justify-content: space-between;

    .MiddleWrapper {
      display: flex;
    }

    .LastSiteMapListWrapper {
      bottom: -29px;
      position: absolute;

      div {
        font-weight: 400;
        font-size: 15px;
        margin-top: 15px;
        color: $sitemap-page-color;

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .CompanyName {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .SiteMapTitle {
    margin-bottom: 18px;
    color: $sitemap-title-color;
    font-size: 16px;
    padding-top: 7px;
  }

  .CompanyInfoWrapper {
    padding-top: 18.62px;
    white-space: nowrap;
    line-height: 145%;

    .CompanyInfoMobile {
      display: none;
    }

    div {
      color: $company-info-color;
      font-weight: 300;
      font-size: 12px;
    }

    span {
      color: $company-info-color;
      font-weight: 300;
      font-size: 12px;
    }
  }

  .SiteInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .BottomSiteInfo {
      display: flex;
      gap: 24px;
      align-items: center;

      .ManagerWrapper {
        span {
          color: $irpr-font-color;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .SiteMapWrapper {
    padding-top: 5px;

    span {
      display: block;
      font-weight: 300;
      cursor: pointer;

      &:hover {
        left: 0;
      }
    }
  }

  .TopSiteInfo {
    display: flex;
    flex-direction: column;

    span {
      color: $footer-font-color;
      cursor: pointer;
    }
  }

  .CopyrightInfo {
    margin-top: 10px;
    white-space: nowrap;

    span {
      font-size: 14px;
      font-weight: 400;
      color: $footer-font-color;
      cursor: pointer;
    }

    div {
      display: flex;
      align-items: center;
      color: $footer-font-color;
    }

    div:first-child {
      margin-bottom: 63px;
    }

    .Copyright {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .Underline {
    display: inline-block;
    position: relative;
    font-size: 14px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -5px;
      left: 0;
      background-color: #909090;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  .SnsWrapper {
    margin-top: 28px;
    display: flex;
    gap: 19px;

    a {
      height: 40px;
    }
  }

  .ShortBreakLine {
    margin: 0 20px;
  }

  .SiteMapListWrapper {
    display: flex;
    white-space: nowrap;
    gap: 28px;
    font-weight: 400;
    font-size: 15px;
    position: relative;

    div {
      color: $sitemap-page-color;
    }

    span {
      width: fit-content;

      &:hover {
        font-weight: 600;
      }
    }

    span:not(:last-of-type) {
      margin-bottom: 8px;
    }

    .SectionPageName:first-of-type {
      margin-top: 15px;
    }

    .SectionPageName {
      margin-bottom: 5px;

      &:hover {
        font-weight: 600;
      }
    }
  }

  .FamilySiteBtn {
    position: relative;
    width: 159px;
    background-color: $footer-font-color;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 6px 12px;
    line-height: 20px;
    font-weight: 400;
    font-size: 12px;

    span {
      width: 100%;
      color: $footer-color;
      cursor: pointer;
      transition: all 0.5s ease;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      width: 100%;

      li {
        color: $familysite-font-color;
        cursor: pointer;
        margin-bottom: 5px;
        line-height: 166.667%;
        padding: 5px 0;

        &:hover {
          font-weight: 600;
        }
      }

      a:not(:last-child) {
        li {
          border-bottom: 1px solid $familysite-li-border-color;
        }
      }
    }
  }

  .FamilySiteBtnDown {
    display: none;
  }

  .Dropdown {
    width: 159px;
    background-color: $footer-font-color;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 20px;
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    bottom: 36px;
    left: 0;

    .FamilySiteName {
      list-style: none;
    }
  }
}

@include laptop {
  .Footer {
    padding-top: 85px;
    padding-bottom: 85px;
    padding-left: 150px;
    padding-right: 150px;

    .CompanyInfoWrapper {
      div {
        font-size: 0.75rem;
      }
    }
  }
}

@include tablet {
  .Footer {
    padding-left: 105px;
    padding-right: 61px;
    padding-top: 52px;
    padding-bottom: 52px;
    position: relative;

    .TopSiteInfo {
      display: none;
    }

    .BottomSiteInfo {
      flex-direction: column;
      height: 100%;
      align-items: flex-end;
      justify-content: space-between;
    }

    .Dropdown {
      top: 36px;
      z-index: 1;
      bottom: unset;
    }

    .FamilySiteBtn {
      display: none;
    }

    .FamilySiteBtnDown {
      @include FamilyBtnDown;
    }

    .FamilySiteBtnDownWrapper {
      position: absolute;
      top: 52px;
      right: 61px;
    }

    .ManagerWrapper {
      display: none;
    }
  }
}

@include mobile {
  .Footer {
    padding-left: 28px;
    padding-right: 32px;
    padding-top: 39px;
    padding-bottom: 44px;

    .ShortBreakLine {
      margin: 0 7px;
    }

    .MainSection {
      flex-direction: column;

      .CompanyName {
        margin-bottom: 26px;
      }

      .CopyrightInfo {
        width: 100%;
        margin-top: 26px;
        white-space: normal;

        .Crop {
          width: 100%;
          .Underline{
            font-size: 0.875rem;
          }
        }

        .Copyright {
          font-size: 3vw;
        }

        .Crop:first-child {
          margin-bottom: 26px;
        }
      }

      .SnsWrapper {
        margin-top: 19px;
        margin-bottom: 27px;
      }

      .BottomSiteInfo {
        align-items: flex-start;
        flex-direction: column;
        gap: 27px;

        .ManagerWrapper {
          display: none;
        }
      }

      .CompanyInfoWrapper {
        padding-top: 21.62px;

        div {
          font-size: 5vw;
          zoom: 0.5;
          line-height: 7vw;
        }
      }

      .CompanyInfoMobile {
        display: block;
      }

      .CompanyInfo {
        display: none;

        span {
          display: none;
        }
      }

      .TopSiteInfo {
        display: none;
      }
    }
  }
}