$scroll-status-light: #f4f4f4;
$scroll-status-dark: #252525;

.ScrollButton{
  position: fixed;
  height: 100vh;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  right: 141.39px;
  .Wrapper {
    display: flex;
    position: relative;
  }
  .ScrollStatusWrapper {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    z-index: 4;
    a{
      width: 16px;
      height: 16px;
      border-radius: 50%;
      cursor: pointer;
      border: 3px $scroll-status-light solid;
    }
    .DarkScrollStatus {
      border: 3px $scroll-status-dark solid;
    }
    .ScrollStatusActive {
      background-color: $scroll-status-light;
    }
    .DarkScrollStatusActive {
      border: 3px $scroll-status-dark solid;
      background-color: $scroll-status-dark;
    }
  }
  button{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    border: 3px $scroll-status-light solid;
  }
  .PageName {
    position: absolute;
    left: -25px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    transform: rotate(-90deg) translate(-100%);
    transform-origin: top left;
  }
  .DarkPageName {
    position: absolute;
    left: -25px;
    font-size: 12px;
    font-weight: 400;
    transform: rotate(-90deg) translate(-100%);
    transform-origin: top left;
    color: $scroll-status-dark;
  }
} 

@include laptop {
  .ScrollButton {
    right: 55px;
  }
}

@include tablet {
  .ScrollButton {
    display: none;
  }
}

@include mobile{
  .ScrollButton{
    display: none;
  }
}
