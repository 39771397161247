@import "../../mixin";

.DetailNews {
    width: 100vw;
    // height: 100vh;
    background-color: #eaebed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 231px 260px 160px 260px;

    .NewsBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .ql-snow{
            width: 100%;
        .ContentsWrapper{
            width: 100%;
        }
        .ql-editor{
            padding:0;
            p,
            s,
            li,
            blockquote,
            span,
            div,
            u,
            em,
            strong,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: black;
            }
            a{
                color: #06c!important;
            }
        }
        }

        .NewsTitle {
            text-align: center;
            color: #252525;
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .MobileNewsName{
            display: none;
        }

        .NewsDate {
            font-size: 20px;
            color: #9a9a9a;
            margin-bottom: 104px;
        }

        .DetailImage{
            width: 70%;
            border-radius: 10px;
            margin-bottom: 135px;
        }

        .Btm {
            width: 100%;
            text-align: left;
            display: flex;
            flex-direction: column;

            .NewsName {
                margin-top: 128px;
                margin-bottom: 13px;
                font-size: 17px;
                color: #9a9a9a;
            }

            .NewsAsk {
                span{
                    color: #5b5b5b;
                font-size: 17px;
                cursor: pointer;
                }

                span:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}

@include tablet{
    .DetailNews{
    padding: 170px 120px 100px 120px;

    .NewsBox{
        .NewsTitle{
            font-size: 4.5vw;
            margin-bottom: 10px;
        }
        .MobileNewsName{
            display: none;
        }
        .NewsDate{
            font-size: 16px;
        }

        .Btm{
            .NewsName{
                font-size: 14px;
            }
            .NewsAsk{
                font-size: 14px;
            }
        }
        
        .DetailImage{
            width: 80%;
            margin-bottom: 100px;
        }
    }
    }
}

@include mobile{
    .DetailNews{
        padding: 100px 50px 60px 50px;

        .NewsBox{
            .NewsTitle{
                text-align: left;
                font-size: 6.5vw;
            }

            .MobileNewsName{
                display: flex;
                color: #9a9a9a;
                font-size: 12px;
                text-align: left;
                width: 100%;
                margin-bottom: 8px;
            }

            .NewsDate{
                font-size: 12px;
                text-align: left;
                width: 100%;
                margin-bottom: 30px;
            }

            .Btm{
                display: none;
            }

            .DetailImage{
                width: 100%;
                margin-bottom: 60px;
            }
        }
    }
}