@import "../../mixin";

.TeamSlide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 213px;

    .Start {
        margin-top: 100px;
        font-size: 40px;
        font-weight: bold;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 32px;
        white-space: pre-line;
        text-align: center;
        padding: 0 10px;
    }

    .HashTagBox {
        display: flex;
        gap: 36px;
        margin-bottom: 140px;
        padding: 0 30px;

        .HashTag {
            border-radius: 20px;
            padding: 8px 18px;
            background-color: #88a9a9;
        }
    }

    .arrow-prev,
    .arrow-next {
        position: absolute;
        top: 33%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 45px;
        height: 45px;
    }

    .arrow-prev {
        left: 10px;
        z-index: 2;
    }

    .arrow-next {
        right: 10px;
    }

    .SlideCarousel {
        width: 920px;

        .Slide {
            display: flex;
            flex-direction: column;
            align-items: center;

            .SlideTextBox {
                margin-top: 87px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .Box {
                    border-radius: 20px;
                    padding: 9px;
                    font-size: 28px;
                    font-weight: bold;
                    margin-bottom: 30px;
                }

                span {
                    font-size: 18px;
                    line-height: 180%;
                    white-space: pre-line;
                }
            }
        }
    }
}

@include laptop {
    .TeamSlide {
        .Start {
            font-size: 35px;
            margin-bottom: 25px;
        }

        .HashTagBox {
            margin-bottom: 50px;
        }

        .SlideCarousel {
            width: 700px;
        }
    }
}

@include tablet {
    .TeamSlide {
        height: auto;
        padding-bottom: 100px;
        padding-top: 100px;

        .Start {
            margin-top: 200px;
            font-size: 3.5vw;
        }

        .HashTagBox {
            width: 70%;
            gap: 15px;
            margin-bottom: 100px;

            .HashTag {
                height: 60px;
                width: 25%;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 2.3vw;
                zoom: 50%;
            }
        }

        .SlideCarousel {
            width: 70%;

            .Slide{
                .SlideTextBox{
                    margin-top: 50px;

                    .Box {
                        font-size: 2.5vw;
                    }
    
                    span  {
                        font-size: 1.5vw;
                    }
                }
            }
        }
    }
}

@include mobile {
    .TeamSlide {
        margin-top: 60px;
        padding-bottom: 60px;
        height: auto;

        .Start {
            margin-top: 200px;
            font-size: 4.5vw;
        }

        .HashTagBox {
            display: none;
        }

        .SlideCarousel {
            width: 70%;

            .Slide{
                width: 100%;
                .SlideTextBox{
                    margin-top: 50px;

                    .Box {
                        font-size: 3.5vw;
                        margin-bottom: 20px;
                    }
    
                    span  {
                        font-size: 2.5vw;
                        line-height: 180%;
                    }
                }
            }
        }

        .arrow-prev,
        .arrow-next {
            top: 22%;
            transform: translateY(-50%);
            background-color: transparent;
            border: none;
            cursor: pointer;
            width: 30px;
            height: 30px;
        }
    }
}