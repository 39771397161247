@import "../../mixin";

.Sevicess {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .TextBox {
    margin-top: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Explan {
      font-size: 20px;
    }

    .Name {
      font-size: 50px;
      font-weight: bold;
      margin-top: 60px;
      margin-bottom: 130px;
    }
  }

  .FlexBox {
    .ServiceExplanBox {
      display: flex;
      margin-bottom: 152px;

      .image-container {
        position: relative;
        display: inline-block;
        width: 180px;
        height: 180px;
        margin-right: 39px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      .overlay-text {
        color: white;
        font-size: 25px;
        font-weight: bold;
      }

      .image-container:hover .overlay {
        opacity: 1;
      }

      .ItemTextBox {
        flex-direction: column;
        display: flex;
        justify-content: center;

        .ItemName {
          font-size: 27px;
          font-weight: bold;
          margin-bottom: 42px;
        }

        .ItemExplan {
          font-size: 18px;
          line-height: 200%;
          white-space: pre-line;
        }
      }
    }
  }
}

.MobileServicess {
  display: none;
}

@include tablet {
  .Sevicess {
    padding-left: 50px;
    padding-right: 50px;

    .FlexBox {
      .ServiceExplanBox {
        margin-bottom: 100px;

        .ItemTextBox {
          .ItemName {
            font-size: 24px;
            margin-bottom: 30px;
          }

          .ItemExplan {
            font-size: 14px;
          }
        }
      }
    }
  }

  .MobileServicess {
    display: none;
  }
}

@include mobile {
  .Sevicess {
    display: none;
  }

  .MobileServicess {
    margin-top: 135px;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .TextName {
      font-size: 15vw;
      zoom: 40%;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .SlideSBox {
      width: 100%;

      .KeyFrame {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .ImgFrame {
          width: 50%;
          height: 100%;
          margin-bottom: 17px;
        }

        .ItemName {
          font-size: 10vw;
          zoom: 40%;
          font-weight: bold;
          margin-bottom: 17px;
        }

        .ItemExplan {
          width: 100%;
          font-size: 6vw;
          zoom: 50%;
          line-height: 170%;
        }
      }
    }
  }
}