@import "../mixin";

.Main {
  height: 100%;

  #page1,
  #page2,
  #page3,
  #page4 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100vh;
    width: 100vw;
    // overflow-y: auto;
  }

  #page2{
    text-align: center;
  }
}

@include desktop{
  #page1,
  #page2,
  #page3{
    height: 100vh;
  }
}