@import "../mixin";

.CulturePage {
    height: 100%;

    #culture1 {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100vh;
        width: 100vw;
    }

    #culture3,
    #culture2 {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100vw;
        height: 100vh;
    }
}

@include tablet {
    .CulturePage {
        #culture1, #culture2,#culture3 {
            height: auto;
        }
    }
}

@include mobile {
    .CulturePage {
        #culture1, #culture2,#culture3 {
            height: auto;
        }
    }
}