@import "../../mixin";

.FirstCulture {
    width: 100vw;
    height: 100%;
    background-image: url("../../../asset/img/Culture/FirstCultureBack_new_size.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding-left: 261px;
    padding-right: 20px;

    .TextCulture{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

    .TabName{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 40px;
    }

    .FCulHead {
        font-size: 45px;
        font-weight: bold;
        margin-bottom: 40px;
        white-space: pre-line;
        text-align: left;
    }

    .FCulBtm {
        font-size: 20px;
        text-align: left;
        line-height: 200%;
        white-space: pre-line;
    }
}
}

@include tablet{
.FirstCulture{
    height: auto;
    padding-top: 200px;
    padding-bottom: 250px;
    background-position: center center;
    background-image: url('../../../asset/img/Culture/TabletFirstCultureBack.svg');
    padding-left: 160px;

    .TextCulture{

    .TabName{
        font-size: 16px;
        margin-bottom: 20px;
    }

    .FCulHead{
        font-size: 4vw;
        margin-bottom: 30px;
    }


    .FCulBtm{
        font-size: 2vw;
    }
}
}
}

@include mobile{
    .FirstCulture{
        background-image: url('../../../asset/img/Culture/TabletFirstCultureBack.svg');
        background-size: 100%;
        background-position: center center;
        padding-left: 50px;
        height: auto;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-top: 150px;

        .TextCulture{

            .TabName{
                font-size: 8px;
                margin-bottom: 7px;
            }
    
        .FCulHead{
            font-size: 8vw;
            zoom: 50%;
        }
    
        .FCulBtm{
            font-size: 5vw;
                zoom: 50%;
            line-height: 170%;
        }
    }
    }
}