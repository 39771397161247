@import "../../mixin";

#service2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.ServiceTab {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    .Tabls{
        width: 60%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

    .SerSolTextBox {
        margin-bottom: 100px;
        flex-direction: column;
        display: flex;
        align-items: flex-start;

        .Service {
            font-size: 20px;
            margin-bottom: 13px;
        }

        .SolSerText {
            text-align: left;
            font-size: 40px;
            font-weight: bold;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            white-space: pre-line;
        }
    }

    .SolSerBox {
        width: 100%;

        .btn-wrap {
            display: flex;
            align-items: center;
            width: 100%;
            height: 169px;
            justify-content: center;
            margin-bottom: 30px;
        }

        .button {
            position: relative;
            width: 100%;
            height: 100%;
            display: inline-block;
            margin: 0;
            border: 0 none;
            outline: none;
            text-decoration: none;
            cursor: pointer;
            -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
            border-radius: 10px;
            transition: color .3s cubic-bezier(.25, .46, .45, .94);
            overflow: hidden;
            text-align: center;

            &:hover {
                
                .SolutionBtn,
                .ServiceTtn {
                    width: 100%;
                    .Left {

                        .SolutionTopText,
                        .SolutionBtmText {
                            color: #252525;
                        }

                        .ServiceTopText,
                        .ServiceBtmText {
                            color: #252525;
                        }
                    }

                    .Right {
                        .SolutionRightText {
                            color: #252525;
                        }

                        .ServiceRightText {
                            color: #252525;
                        }
                    }


                }

                .button-overlay:after {
                    transform: scale(2.0);
                }

            }

            &:before {
                position: absolute;
                top: 100%;
                left: 0;
                content: "";
                color: #000;
                border-radius: 10px;
            }

            .SolutionBtn,
            .ServiceTtn {
                border: 1px solid #EAEBED;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                padding-left: 75px;
                padding-right: 75px;
                border-radius: 10px;
                cursor: pointer;
                position: relative;
                overflow-x: hidden;

                .Left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 20px;

                    .SolutionTopText,
                    .ServiceTopText {
                        font-size: 28px;
                        font-weight: bold;
                    }

                    .SolutionBtmText,
                    .ServiceBtmText {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .SolutionTopText,
                    .SolutionBtmText {
                        color: #EAEBED;
                    }

                    .ServiceTopText,
                    .ServiceBtmText {
                        color: #EAEBED;
                    }
                }

                .Right {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    margin-bottom: 50px;

                    span {
                        margin-right: 10px;
                        font-size: 15px;
                    }

                    img {
                        margin-bottom: 3px;
                    }

                    .SolutionRightText {
                        color: #EAEBED;
                    }

                    .ServiceRightText {
                        color: #EAEBED;
                    }
                }
            }
        }

        .button-overlay {
            height: auto;
            width: 100%;
            position: absolute;
            top: 100%;
            right: 100%;

            &:before {
                padding-top: 100%;
                display: block;
                content: "";
            }

            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 100%;
                content: "";
                transition: transform .5s cubic-bezier(.25, .46, .45, .94);
                background-color: #EAEBED;
                border-radius: 10px;
                transform: scale(1);
                transform-origin: bottom left;
            }
        }

        .button-text {
            position: relative;
            display: inline-block;
        }
    }
    }
}

@include laptop {
    .ServiceTab {
        .Tabls{
        .SerSolTextBox {
            margin-bottom: 60px;
        }
    }
    }
}

@include tablet {
    #service2 {
        height: auto;
    }

    .ServiceTab {
        height: auto;
     padding: 60px;
     .Tabls{
        width: 100%;
        .SerSolTextBox{
            margin-bottom: 60px;
            .SolSerText{
                font-size: 8vw;
                zoom: 50%;
            }
        }

        .SolSerBox {
            width: 100%;
            .btn-wrap{
                width: 100%;
                .SolutionBtn,
                .ServiceTtn {
                    padding-left: 55px;
                    padding-right: 55px;
                }
            }
        }
    }
}
}

@include mobile {

    #service2 {
        height: auto;
    }

    .ServiceTab {
        height: auto;
        padding: 60px 30px;
        .Tabls{
            width: 100%;

        .SerSolTextBox {
            margin-bottom: 50px;

            .Service {
                font-size: 7vw;
                zoom: 50%;
                margin-bottom: 13px;
            }

            .SolSerText {
                font-size: 10vw;
                zoom: 50%;
            }
        }

        .SolSerBox {
width: 100%;
            .btn-wrap{
                width: 100%;
                height: 120px;

                .SolutionBtn,
            .ServiceTtn {
                padding-left: 25px;
                padding-right: 25px;

                .Left {

                    .TapNotImag{
                        display: none;
                    }

                    .SolutionTopText,
                    .ServiceTopText {
                        font-size: 20px;
                    }

                    .SolutionBtmText,
                    .ServiceBtmText {
                        font-size: 14px;
                    }
                }

                .Right {
                    margin-bottom: 50px;

                    .TapNotImag,
                    .ServiceRightText,
                    .SolutionRightText{
                        display: none;
                    }

                    span {
                        font-size: 12px;
                    }
                }
            }
            }

        }
        }
    }
}