.NewsRoomPost {
  width: 100vw;
  // height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../asset/img/Manager/NewsRoom/ManagerNewsRoomBg.svg");
  background-size: cover;
  .Blur {
    padding-top: 207px;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;

    .MainContainer {
      width: 100%;
      padding: 0 10%;
    }

    .NewsRoomTitle {
      color: white;
      font-size: 30px;
      font-weight: 700;
    }
    .Container {
      margin-top: 65px;
      .ErrorMessage {
        color: #ff6767;
        font-size: 12px;
      }
      .LineContainer {
        display: flex;
        gap: 24px;
      }
      .InputContainer {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        .ContentTitle {
          margin-top: 25px;
        }
        .PostTitle {
          margin-top: 30px;
        }
        .PostTitleInput {
          width: 100% !important;
        }
        .InputTitle {
          margin-top: 30px;
          text-wrap: nowrap;
        }
        .Category {
          width: 100%;
          padding-top: 11px;
          padding-bottom: 11px;
          border-radius: 15px;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 28px;
          padding-right: 20px;
          position: relative;
          text-wrap: nowrap;
          cursor: pointer;
          span {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            color: black;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .Dropdown {
            position: absolute;
            bottom: -83px;
            left: 0;
            width: 100%;
            padding-top: 17px;
            padding-bottom: 11px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 28px;
            padding-right: 20px;
            flex-direction: column;
            span {
              padding-bottom: 12px;
            }
          }
        }
        .Input {
          border-radius: 15px;
          border: 1px solid #fff;
          background: rgba(255, 255, 255, 0.5);
          padding: 11px 15px;
          font-size: 18px;
          font-weight: 700;
          color: white;
          width: 100%;
          height: 45px;
          &:focus {
            outline: none;
          }
        }
      }
      .BtnContainer {
        margin-top: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 117px;
        div {
          margin-top: 58px;
          border-radius: 15px;
          border: 1px solid #fff;
          padding-top: 9px;
          padding-bottom: 8px;
          padding-left: 181px;
          padding-right: 181px;
          font-size: 22px;
          font-weight: 700;
          white-space: nowrap;
          background: rgba(187, 187, 187, 0.5);
          &.active {
            background-color: #ffb800;
            cursor: pointer;
          }
        }
      }
    }
  }

  .quill {
    .ql-container.ql-snow {
      border: none;
      height: 456px;
    }
    .ql-toolbar.ql-snow {
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .ql-picker-label {
      color: black;
    }
    .ql-editor {
      p,
      s,
      li,
      blockquote,
      span,
      div,
      u,
      em,
      strong,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: black;
      }
      span {
        margin: unset !important;
      }
    }
    .ql-picker-item {
      color: black;
    }
  }
}

.scrollHidden {
  height: 100%;
  min-height: 100%;
  overflow: hidden !important;
  touch-action: none;
}

.ql-snow .ql-tooltip input[type="text"] {
  display: none;
  border: 1px solid #ccc;
  font-size: 13px;
  height: 26px;
  margin: 0px;
  padding: 3px 5px;
  width: 170px;
  color: #000;
}

@media all and (max-width: 768px) {
  .LineContainer {
    flex-direction: column;
  }
}
