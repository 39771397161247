.ManagerHeader {
    padding-left: 261px;
    padding-right: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 73px;
    width: 100vw;
    background-color: rgba(255, 0, 0, 0);
    position: fixed;
    top: 0;
    z-index: 2;

    .Left{
        .HeaderName{
            margin-left: 26px;
            font-size: 13px;
        }
    }

    .Right{
        .Manager{
            margin-right: 18px;
            font-size: 13px;

            .Name{
                font-weight: bold;
            }
        }

        .Logout{
            width: 80px;
            height: 30px;
            border-radius: 15px;
            background-color: #f1b61f;
            font-size: 13px;
            font-weight: bold;
            border: none;
        }
    }
}