.ManagerLogin {
  height: 100vh;
  background-image: url("../../asset/img/Manager/Login/LoginBack.svg");
  background-repeat: no-repeat;
  background-size: 100%;

  .Blur {
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .MainBtn {
      top: 32px;
      left: 33px;
      position: absolute;
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      border-radius: 15px;
      color: black;
      padding-top: 7px;
      padding-right: 21px;
      padding-bottom: 8px;
      padding-left: 20px;
      line-height: 15px;
    }

    .Manager {
      margin-top: 20px;
      font-size: 20px;
      margin-bottom: 84px;
    }

    .LoginForm {
      margin-bottom: 60px;

      .IdBox,
      .PwBox {
        width: 448px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        span {
          font-size: 15px;
          margin-bottom: 5px;
          margin-left: 11px;
        }

        .Id {
          height: 45px;
          border-radius: 15px;
          padding-left: 15px;
          padding-right: 15px;
          color: #252525;
          border: none;
        }

        .Id:focus {
          outline: none;
        }
      }

      .PwBox {
        width: 100%;
        height: 45px;

        .PasswordInput {
          padding-right: 15px;
          height: 45px;
          width: 100%;
          background-color: #fff;
          border-radius: 15px;
          display: flex;
          justify-content: space-between;

          .Pw {
            height: 45px;
            width: 80%;
            border-radius: 15px;
            padding-left: 15px;
            border: none;
            color: #252525;
          }

          .Pw:focus {
            outline: none;
          }
        }
      }
    }

    .Manager_Login {
      width: 212px;
      height: 45px;
      border-radius: 15px;
      background: #f1b61f;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      border: none;
      font-size: 25px;
      font-weight: bold;
    }
  }
}
