@import "../../mixin";

.HomeService {
    width: 100vw;
    height: 100vh;
    background-image: url("../../../asset/img/Service/ServiceMain.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding-left: 261px;

    .TextHome {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .TabName {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 40px;
        }

        .FCulHead {
            font-size: 45px;
            font-weight: bold;
            margin-bottom: 40px;
        }

        .FCulBtm {
            font-size: 20px;
            text-align: left;
            line-height: 200%;
            white-space: pre-line;
        }
    }
}

@include tablet {
    .HomeService {
        height: auto;
        padding-top: 300px;
        padding-bottom: 250px;
        background-image: url("../../../asset/img/Service/ServiceMainTab.png");
        background-position: center center;
        padding-left: 100px;

        .TextHome {

            .TabName {
                font-size: 16px;
                margin-bottom: 20px;
            }

            .FCulHead {
                font-size: 4vw;
                margin-bottom: 30px;
            }


            .FCulBtm {
                font-size: 2vw;
            }
        }
    }
}

@include mobile {
    .HomeService {
        margin-top: 80px;
        height: auto;
        padding-top: 80px;
        padding-bottom: 80px;
        background-image: url("../../../asset/img/Service/ServiceMainTab.png");
        background-size: 100%;
        background-position: center center;
        padding-left: 50px;

        .TextHome {

            .TabName {
                font-size: 8px;
                margin-bottom: 7px;
            }

            .FCulHead {
                font-size: 8vw;
                zoom: 50%;
                margin-bottom: 7px;
            }

            .FCulBtm {
                font-size: 5vw;
                zoom: 50%;
                line-height: 170%;
            }
        }
    }
}