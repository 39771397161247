@import "../../mixin";

$newsroom-background-color: #eaebed;
$new-content-font-color: #545454;
$new-content-title-color: #252525;
$date-font-color: #858585;
$tab-active-color: #88a9a9;
$page-color: #3e3e3e;
$active-page-background-color: #727272;
$active-page-font-color: #F1F1F1;

.NewsRoom {
  background-image: url("../../../asset/img/Community/NewsRoomBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 170px;
  padding-bottom: 178px;
  padding-left: 260px;
  padding-right: 142px;
  background-color: #eaebed;
  width: 100vw;
  background-color: $newsroom-background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 260px;
  padding-right: 142px;

  .Page {
    width: 100%;

    .NewContentContainer {
      margin-bottom: 136px;

      // height: 358px;
      .NewContent {
        color: $new-content-font-color;
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 30px;
      }

      .ContentContainer {
        display: flex;
        gap: 67px;
        width: 100%;

        &:hover {
          .NewContentMainTitle {
            color: #88a9a9;
          }
          .NewContentImgWrapper{
            transform: scale(1.1);
            transition: .5s;
          }
        }

        .NewContentImgWrapper {
          width: 35%;
          // height: 358px;
          border-radius: 10px;
          overflow: hidden;
          cursor: pointer;
          &:hover{
            transform: scale(1.1);
            transition: .5s;
          }
          img {
            width: 100%;
            height: 100%;
        
          }
        }
  
        .TextContainer {
          width: 65%;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          word-wrap: break-word;
          height: fit-content;
          cursor: pointer;
  
          .NewContentMainTitle {
            font-size: 30px;
            font-weight: 700;
            color: $new-content-title-color;
            margin-bottom: 20px;
          }
  
          .SmallTitle {
            color: #252525;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 15px;
          }
  
          .Date {
            color: $date-font-color;
            font-size: 15px;
            font-weight: 300;
          }
        }
      }

    }

    .TabSearch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 60px;

      .Tab {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;

        .Blog{
          font-size: 30px;
          color: #252525;
          font-weight: bold;
        }

        .TabItem {
          cursor: pointer;
          padding-top: 8px;
          padding-bottom: 9px;
          padding-right: 14px;
          padding-left: 15px;
          color: #252525;
          font-size: 30px;
          font-weight: 700;
          border-radius: 15px;

          &.Active {
            background-color: $tab-active-color;
            color: white;
          }
        }
      }

      .SearchBox {
        width: 449px;
        height: 46px;
        border: 1px solid #88A9A9;
        border-radius: 15px;
        background-color: rgba(255, 0, 0, 0);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 12px;
        padding-left: 15px;
        top: 0;
        position: sticky;
        right: 0;

        .SearchText {
          background-color: rgba(255, 0, 0, 0);
          border: none;
          height: 45px;
          width: 390px;
          border-radius: 15px;
          color: gray;
        }

        .SearchText:focus {
          outline: none;
        }
      }
    }

    .Posts {
      display: flex;
      flex-direction: column;
      gap: 180px;
      margin-bottom: 94px;
      // height: 1188px;

      .PostContainer {
        cursor: pointer;
        display: flex;
        gap: 140px;
        width: 100%;

        .PostImgWrapper {
          width: 30%;
          // height: 237px;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .PostTextContainer {
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .PostTitle {
            color: #252525;
            font-size: 25px;
            font-weight: 700;
          }

          .PostSmallTitle {
            color: #252525;
            font-size: 18px;
            font-weight: 400;
            margin-top: 15px;
            margin-bottom: 10px;
          }

          .Date {
            margin-top: 15px;
            font-size: 15px;
            color: #858585;
            font-weight: 300;
          }
        }

        &:hover {
          .PostImgWrapper{
            transform: scale(1.15);
            transition: .5s;
          }
          .PostTitle {
            color: #88a9a9;
          }
        }
      }
    }

    .PageContainer {
      display: flex;
      justify-content: center;

      img {
        cursor: pointer;
      }

      img:first-child {
        margin-right: 36.69px;
      }

      img:last-child {
        margin-left: 36.69px;
      }

      button:not(:last-of-type) {
        margin-right: 15px;
      }

      button {
        border-radius: 50%;
        padding-left: 11px;
        padding-right: 11px;
        width: 34px;
        height: 34px;
        font-size: 20px;
        font-weight: 400;
        color: #3e3e3e;

        &.Active {
          background-color: #727272;
          color: $active-page-font-color;
        }
      }
    }
  }
}
.MibilePstContain{
  display: none;
}

@include tablet {
  .MibilePstContain{
    display: none;
  }
  .NewsRoom {
    padding-left: 90px;
    padding-right: 90px;

    .Page {
      .NewContentContainer {
        margin-bottom: 80px;
        
        .ContentContainer {
          gap: 35px;
          width: 100%;

          .NewContentImgWrapper {
            width: 30%;
          }

          .TextContainer {
            width: 70%;
  
            .NewContentMainTitle {
              font-size: 35px;
              margin-bottom: 10px;
            }
  
            .SmallTitle {
              font-size: 10px;
              margin-bottom: 11px;
            }
  
            .Date {
              font-size: 16px;
            }
          }
        }

      }

      .TabSearch {
        margin-bottom: 30px;

        .Tab {
          gap: 12px;

          .TabItem {
            font-size: 16px;
          }
        }

        .SearchBox {
          width: 300px;
        }
      }

      .Posts {
        margin-bottom: 60px;
        gap: 40px;

        .PostContainer {
          gap: 60px;

          .PostImgWrapper {
            width: 300px;
          }

          .Date {
            font-size: 10px;
          }

          .PostTextContainer{
            .PostTitle{
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

@include mobile{
  .NewsRoom{
    padding: 100px 32px 100px 30px;

    .Page{
      .NewContentContainer{
        margin-bottom: 70px;
        .ContentContainer{
          flex-direction: column;
          gap: 30px;

          .NewContentImgWrapper{
            width: 100%;
          }

          .TextContainer{
            align-items: center;
            .NewContentMainTitle{
             font-size: 8.5vw;
              margin-bottom: 10px;
            }

            .SmallTitle{
              font-size: 9px;
              margin-bottom: 10px;
            }

            .Date{
              font-size: 10px;
              width: 100%;
              text-align: right;
            }
          }
        }
      }

      .TabSearch{
        flex-direction: column-reverse;

        .SearchBox{
          width: 100%;
          margin-bottom: 30px;
        }

        .Tab{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 35px;

          .TabItem{
            font-size: 25px;
          }
        }
      }

      .Posts{
        display: none;
      }
      
      .PostImgWrapper {
        border-radius: 10px;
        overflow: hidden;
        img{
        height: 358px;
        }
      }
      .PageContainer{
        display: none;
      }
    }
  }

  .MibilePstContain{
    display: flex;

    .PostTextContainer{
      .PostTitle{
        color: #252525;
        font-size: 7.5vw;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 5px;
      }

      .PostSmallTitle{
        color: #252525;
        font-size: 12px;
      }

      .Date{
        color: #858585;
        font-weight: 300;
        width: 100%;
        text-align: right;
        font-size: 3vw;
        zoom: 50%;
        margin-top: 10px;
      }
    }
  }
}