@import "../../mixin";

.ContactUs {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f4246;

  .ContactInput {
    width: 442px;
    margin-right: 144px;

    .EmailContact {
      margin-bottom: 20px;

      .EmailError {
        display: flex;
        align-items: flex-end;
        margin-bottom: 5px;

        .Title {
          font-size: 15px;
          margin-right: 15px;
        }

        .ErrorMessage {
          font-size: 10px;
          color: #ff7676;
        }
      }

      .Email {
        width: 100%;
        border-radius: 10px;
        height: 44px;
        border: none;
        color: #252525;
        padding-left: 18px;
        padding-right: 20px;
      }

      .Email:focus {
        outline: none;
      }
    }

    .ContactTitle {
      margin-bottom: 20px;

      .EmailError {
        display: flex;
        align-items: flex-end;
        margin-bottom: 5px;

        .Title {
          font-size: 15px;
          margin-right: 15px;
        }

        .ErrorMessage {
          font-size: 10px;
          color: #ff7676;
        }
      }

      .InputTitle {
        width: 100%;
        border-radius: 10px;
        height: 44px;
        border: none;
        color: #252525;
        padding-left: 18px;
        padding-right: 20px;
      }

      .InputTitle:focus {
        outline: none;
      }
    }

    .ContactDetail {
      margin-bottom: 36px;

      .EmailError {
        display: flex;
        align-items: flex-end;
        margin-bottom: 5px;

        .Title {
          font-size: 15px;
          margin-right: 15px;
        }

        .ErrorMessage {
          font-size: 10px;
          color: #ff7676;
        }
      }

      .InputDetail {
        padding: 15px 19px;
        height: 337px;
        width: 100%;
        border-radius: 10px;
        border: none;
        color: #252525;
        resize: none;
      }

      .InputDetail:focus {
        outline: none;
      }
    }

    .InputBox {
      display: flex;
      justify-content: space-between;
      padding-left: 17px;
      align-items: center;

      .InputButton {
        padding: 11px 20px 10px 21px;
        font-size: 18px;
        border-radius: 10px;
        border: none;
      }
    }
  }

  .ContactService {
    .ServiceTitle {
      font-size: 40px;
      margin-bottom: 77px;
    }

    .ServiceTitle::after {
      content: "";
      margin-left: 0.6rem;
      border-right: 2px solid #777;
      animation: cursor 0.9s infinite steps(2);
    }

    .Tel {
      font-size: 16px;
      margin-bottom: 9px;
    }

    .Time {
      font-size: 14px;
      color: #909090;
      margin-bottom: 26px;
    }

    .Ask {
      font-size: 14px;
      color: #909090;
    }

    .blur-animation {
      animation: blur 6s ease;
    }
  }
}

.TabletContactUs {
  display: none;
}

@include laptop {
  .ContactUs {
    .ContactInput {
      .EmailContact {
        margin-bottom: 15px;
      }

      .ContactTitle {
        margin-bottom: 15px;
      }

      .ContactDetail {
        margin-bottom: 25px;

        .InputDetail {
          height: 280px;
        }
      }
    }
  }

  .TabletContactUs {
    display: none;
  }
}

@include tablet {
  .ContactUs {
    display: none;
  }

  .TabletContactUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #3f4246;
    padding-top: 120px;
    padding-bottom: 120px;

    .ServiceTitle {
      font-size: 40px;
      margin-bottom: 77px;
    }

    .ServiceTitle::after {
      content: "";
      margin-left: 0.6rem;
      border-right: 2px solid #777;
      animation: cursor 0.9s infinite steps(2);
    }

    .ContactInput {
      width: 442px;

      .EmailContact {
        margin-bottom: 20px;

        .EmailError {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .Title {
            font-size: 15px;
            margin-right: 15px;
          }

          .ErrorMessage {
            font-size: 10px;
            color: #ff7676;
          }
        }

        .Email {
          width: 100%;
          border-radius: 10px;
          height: 44px;
          border: none;
          color: #252525;
          padding-left: 18px;
          padding-right: 20px;
        }

        .Email:focus {
          outline: none;
        }
      }

      .ContactTitle {
        margin-bottom: 20px;

        .EmailError {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .Title {
            font-size: 15px;
            margin-right: 15px;
          }

          .ErrorMessage {
            font-size: 10px;
            color: #ff7676;
          }
        }

        .InputTitle {
          width: 100%;
          border-radius: 10px;
          height: 44px;
          border: none;
          color: #252525;
          padding-left: 18px;
          padding-right: 20px;
        }

        .InputTitle:focus {
          outline: none;
        }
      }

      .ContactDetail {
        margin-bottom: 36px;

        .EmailError {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .Title {
            font-size: 15px;
            margin-right: 15px;
          }

          .ErrorMessage {
            font-size: 10px;
            color: #ff7676;
          }
        }

        .InputDetail {
          padding: 15px 19px;
          height: 337px;
          width: 100%;
          border-radius: 10px;
          border: none;
          color: #252525;
          resize: none;
        }

        .InputDetail:focus {
          outline: none;
        }
      }

      .InputBox_tablet {
        display: flex;
        justify-content: space-between;
        padding-left: 17px;
        align-items: center;

        .InputButton {
          padding: 11px 20px 10px 21px;
          font-size: 18px;
          border-radius: 10px;
          border: none;
        }
      }
    }

    .ContactService {
      margin-top: 60px;

      .Tel {
        font-size: 16px;
        margin-bottom: 9px;
      }

      .Time {
        font-size: 14px;
        color: #909090;
        margin-bottom: 26px;
      }

      .Ask {
        font-size: 14px;
        color: #909090;
      }

      .blur-animation {
        animation: blur 6s ease;
      }
    }
  }
}

@include mobile {
  .ContactUs {
    display: none;
  }

  .TabletContactUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #3f4246;
    padding: 120px 15px;

    .ServiceTitle {
      font-size: 1.8rem;
      margin-bottom: 77px;
      text-align: center;
    }

    .ServiceTitle::after {
      content: "";
      margin-left: 0.6rem;
      border-right: 2px solid #777;
      animation: cursor 0.9s infinite steps(2);
    }

    .ContactInput {
      width: 90%;

      .EmailContact {
        margin-bottom: 20px;

        .EmailError {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .Title {
            font-size: 15px;
            margin-right: 15px;
          }

          .ErrorMessage {
            font-size: 10px;
            color: #ff7676;
          }
        }

        .Email {
          width: 100%;
          border-radius: 10px;
          height: 44px;
          border: none;
          color: #252525;
          padding-left: 18px;
          padding-right: 20px;
        }

        .Email:focus {
          outline: none;
        }
      }

      .ContactTitle {
        margin-bottom: 20px;

        .EmailError {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .Title {
            font-size: 15px;
            margin-right: 15px;
          }

          .ErrorMessage {
            font-size: 10px;
            color: #ff7676;
          }
        }

        .InputTitle {
          width: 100%;
          border-radius: 10px;
          height: 44px;
          border: none;
          color: #252525;
          padding-left: 18px;
          padding-right: 20px;
        }

        .InputTitle:focus {
          outline: none;
        }
      }

      .ContactDetail {
        margin-bottom: 36px;

        .EmailError {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .Title {
            font-size: 15px;
            margin-right: 15px;
          }

          .ErrorMessage {
            font-size: 10px;
            color: #ff7676;
          }
        }

        .InputDetail {
          padding: 15px 19px;
          height: 337px;
          width: 100%;
          border-radius: 10px;
          border: none;
          color: #252525;
          resize: none;
        }

        .InputDetail:focus {
          outline: none;
        }
      }

      .InputBox_tablet {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .BoxCheck {
          margin-bottom: 10px;
        }

        .InputButton {
          padding: 11px 20px 10px 21px;
          font-size: 18px;
          border-radius: 10px;
          border: none;
        }
      }
    }

    .ContactService {
      margin-top: 60px;

      .Tel {
        font-size: 16px;
        margin-bottom: 9px;
      }

      .Time {
        font-size: 14px;
        color: #909090;
        margin-bottom: 26px;
      }

      .Ask {
        font-size: 14px;
        color: #909090;
      }

      .blur-animation {
        animation: blur 6s ease;
      }
    }
  }
}
