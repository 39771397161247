@import "../mixin";

.DeskTopHeader {
  padding-left: 260px;
  padding-right: 260px;
  height: 80px;
  background-color: rgba(255, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  z-index: 1000;
  position: fixed;
  top: 0;
  transition: transform 0.3s ease-in-out;

  &.hidden {
    transform: translateY(-100%);
  }

  .HeaderMenu {
    display: flex;
    transition: opacity 0.5s ease, transform 0.5s ease;

    .Menus {
      margin-right: 40px;
      padding: 10px 13px;
      border-radius: 15px;
      transition: background-color 0.5s ease, font-weight 0.5s ease;

      span {
        font-size: 13px;
      }

      span:hover {
        font-weight: 600;
      }
    }

    .history-section {
      span {
        color: #252525;
      }
    }

    .Menus:last-child {
      margin-right: 0;
    }
  }

  .lng {
    position: absolute;
    top: center;
    right: 3%;

    .history-section {

      color: #252525;

    }

    button {
      padding: 10px 13px;
      border-radius: 15px;
    }
  }
}

.TabletHeader {
  display: none;
}



@include tablet {
  .DeskTopHeader {
    display: none;
  }

  .TabletHeader {
    padding-top: 23px;
    display: inline-block;
    padding-left: 130px;
    padding-right: 130px;
    background-color: rgba(255, 0, 0, 0);
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;

    .Logo {
      display: flex;
      justify-content: space-between;

      .dropbtn {
        cursor: pointer;
        z-index: 99;
      }

    }

    .dropdown-content {
      display: none;
      margin-top: 23px;
      width: 100vw;
      position: absolute;
      left: 0px;
      z-index: 1;
      font-weight: 400;
      background-color: #252525;
      padding-left: 10px;
      padding-bottom: 10px;

      .Link {
        display: block;
        text-decoration: none;
        font-size: 12px;
        padding: 12px 20px;
      }
    }

    .history-dropdown {
      background-color: #ffffff;

      .history-Link {
        color: #252525;
      }
    }

    .dropdown-content.show {
      display: block;
      .lng{
        padding: 10px 0 10px 20px;
      }
    }
  }
}

@include mobile {
  .DeskTopHeader {
    display: none;
  }

  .TabletHeader {
    padding-top: 23px;
    display: inline-block;
    padding-left: 50px;
    padding-right: 50px;
    background-color: rgba(255, 0, 0, 0);
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;

    .Logo {
      display: flex;
      justify-content: space-between;

      .dropbtn {
        cursor: pointer;
        z-index: 99;
      }

    }

    .dropdown-content {
      display: none;
      margin-top: 23px;
      width: 100vw;
      position: absolute;
      left: 0px;
      z-index: 1;
      font-weight: 400;
      background-color: #252525;
      padding-left: 10px;

      .Link {
        display: block;
        text-decoration: none;
        font-size: 12px;
        padding: 12px 20px;
      }
    }

    .history-dropdown {
      background-color: #ffffff;

      .history-Link {
        color: #252525;
      }
    }

    .dropdown-content.show {
      display: block;
      .lng{
        padding: 10px 0 10px 20px;
      }
    }
  }
}