@import "../../mixin";


.HistoryVBer {
    height: 100vh;
    background-color: #EAEBED;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    align-items: center;
    justify-content: center;

    .History {
        display: flex;
        width: 100%;
        height: 70%;
        align-items: center;
        justify-content: center;

        .LeftHis {
            margin-right: 126px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            padding-top: 21px;

            .Investment {
                font-size: 23px;
                color: #727880;
                margin-bottom: 60px;
                background-color: rgba(255, 0, 0, 0);
            }

            .HistoryYears {
                display: flex;
                flex-direction: column;

                button {
                    padding: 5px 18px;
                    border-radius: 15px;
                    font-size: 20px;
                    font-weight: bold;
                    cursor: pointer;
                    border: none;
                    background-color: rgba(255, 0, 0, 0);
                    margin-bottom: 36px;
                    transition: background-color 0.3s ease;
                }

                button:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .RightHis {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 40%;

            .HistoryName {
                font-size: 40px;
                font-weight: bold;
                color: #252525;
            }

            .VersionContainer {
                transition: opacity 0.3s ease;
                line-height: 200%;
            }
        }

        .TabletVersionContainer {
            display: none;
        }
    }

    .MobileHistory {
        display: none;
    }
}

.History2019 {
    margin-top: 60px;

    .Years2019 {
        color: #252525;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 46px;
    }

    .BoldHis {
        color: #252525;
        font-size: 22px;
        margin-bottom: 20px;
        line-height: 180%;
        white-space: pre-line;

        span {
            color: #252525;
            font-size: 18px;
        }
    }

    .His2019 {
        color: #252525;
        font-size: 18px;
        line-height: 200%;
        white-space: pre-line;
    }
}


@include laptop {
    .History {
        .HistoryName {
            margin-bottom: 15px;
            font-size: 34px;
        }

        .Investment {
            margin-bottom: 15px;
            font-size: 20px;
        }

        .VersionContainer {
            line-height: 170%;
        }
    }

    .History2019 {
        // margin-top: 40px;

        .Years2019 {
            margin-bottom: 20px;
        }

        .BoldHis {
            margin-bottom: 20px;
            font-size: 18px;
        }

        .His2019 {
            font-size: 16px;
            line-height: 170%;
            white-space: pre-line;
        }
    }
}

@include tablet {

    .HistoryVBer {

        // height: 70vh;
        // padding-bottom: 100px;
        .History {

            .LeftHis {
                margin-right: 60px;
            }

            .RightHis {
                width: 70%;

                .VersionContainer {
                    display: none;
                }

                .TabletVersionContainer {
                    transition: opacity 0.3s ease;
                    line-height: 200%;
                    display: flex;
                }
            }

            .History2019 {

                .Years2019 {
                    margin-bottom: 20px;
                }

                .BoldHis {
                    margin-bottom: 20px;
                    font-size: 2vw;
                }

                .His2019 {
                    font-size: 1.8vw;
                    line-height: 170%;
                }
            }
        }
    }
}

@include mobile {

    .HistoryVBer {
        height: auto;

        .History {
            display: none;
        }

        .MobileHistory {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 0 30px;

            .MobileHissstory {
                padding-top: 90px;
                padding-bottom: 80px;
                width: 100vw;
                background-color: #EAEBED;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;

                .MobileHisText {
                    font-size: 12vw;
                    zoom: 50%;
                    color: #252525;
                    font-weight: bold;
                    margin-bottom: 60px;
                }

                .HistoryFlex {
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                    gap: 50px;
                }
            }

            .Mobile2023 {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                .MobileYears {
                    font-size: 25px;
                    font-weight: bold;
                    color: #252525;
                }

                .TextBox {
                    margin-top: 19px;

                    .BoldText {
                        line-height: 180%;
                        font-size: 5vw;
                        zoom: 50%;
                        font-weight: bold;
                        color: #252525;
                        white-space: pre-line;
                    }

                    .Texts {
                        line-height: 180%;
                        font-size: 5vw;
                        zoom: 50%;
                        color: #252525;
                        white-space: pre-line;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}