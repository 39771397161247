@keyframes blurDown {
    from {
        opacity: 0;
        transform: translateY(-40px);
        transition: opacity 2s ease, transform 2s ease;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes blur {
    from {
        opacity: 0;
        transform: translateY(0px);
        transition: opacity 1s ease, transform 1s ease;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes blurUp {
    from {
        opacity: 0;
        transform: translateY(40px);
        transition: opacity 2s ease, transform 2s ease;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes MainSlogan {
    from {
        opacity: 0;
        transform: translateX(-100px);
        transition: opacity 3s ease, transform 3s ease;
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
