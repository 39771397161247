.ManagerPage {
  height: 100vh;
  background-image: url("../../asset/img/Manager/ManagerPage/ManagerBack.png");
  background-repeat: no-repeat;
  background-size: 100%;

  .Blur {
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    padding-top: 225px;

    .MainContainer {
      width: 100%;
      padding: 0 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .HiHello {
      font-size: 30px;
      margin-bottom: 30px;
      text-align: center;
    }

    .SearchBox {
      max-width: 449px;
      width: 100%;
      height: 46px;
      border: 1px solid #fff;
      border-radius: 15px;
      background-color: rgba(255, 0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 12px;
      padding-left: 15px;
      margin-bottom: 138px;

      .SearchText {
        background-color: rgba(255, 0, 0, 0);
        border: none;
        height: 45px;
        width: 390px;
        border-radius: 15px;
      }

      .SearchText:focus {
        outline: none;
      }

      .SearchText::placeholder {
        color: #fff;
      }
    }

    .SelectMenu {
      .FirstLine {
        display: flex;
        margin-bottom: 26px;

        .AnyBox {
          text-wrap: nowrap;
          padding-left: 18px;
          padding-right: 18px;
          border-right: 1px solid #fff;

          div {
            padding: 2px 23px;
            font-size: 30px;
            font-weight: bold;
            border: none;
            border-radius: 15px;
          }
          div:hover {
            background-color: #ffb800;
          }
        }

        .AnyBox:last-child {
          border-right: none;
        }
      }

      .SecondLine {
        display: flex;

        .AnyBox {
          padding-left: 18px;
          padding-right: 18px;
          border-right: 1px solid #fff;

          div {
            padding: 2px 23px;
            font-size: 30px;
            font-weight: bold;
            border: none;
            border-radius: 15px;
          }
          div:hover {
            background-color: #ffb800;
          }
        }

        .AnyBox:last-child {
          border-right: none;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .FirstLine {
    flex-direction: column;
    gap: 10px;
  }

  .SecondLine {
    flex-direction: column;
    gap: 10px;
  }

  .AnyBox:last-child {
    border-right: 1px solid #fff;
  }
}
