@import "../../mixin";

.TeamCulture {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .TitleBox {
        display: flex;
        flex-direction: column;

        .TeamTitle {
            font-size: 20px;
            margin-bottom: 60px;
        }

        .ActiveTitle {
            font-weight: bold;
            font-size: 40px;
            margin-bottom: 60px;
        }
    }

    .Top {
        display: flex;
        align-items: center;
        justify-content: center;

        .card {
            cursor: pointer;
            width: 291px;
            height: 465px;
            border-radius: 10px;
            perspective: 1100px;
            position: relative;
            transition: 0.4s;
            transform-style: preserve-3d;
            margin-right: 22px;

            .FrontOne {
                background-image: url("../../../asset/img/Culture/TeamBackOne.png");
                white-space: pre-line;
            }

            .FrontTwo {
                background-image: url("../../../asset/img/Culture/TeamBackTwo.png");
                white-space: pre-line;
            }

            .FrontThree {
                background-image: url("../../../asset/img/Culture/TeamBackThree.png");
                white-space: pre-line;
            }

            .FrontOne,
            .FrontTwo,
            .FrontThree,
            .BackOne,
            .BackTwo,
            .BackThree {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .FrontOne,
            .FrontTwo,
            .FrontThree {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: left;
                justify-content: center;
                padding: 0 10px;

                span {
                    font-size: 30px;
                    font-weight: bold;
                }

                .FrontPlus {
                    width: 45px;
                    height: 45px;
                    position: absolute;
                    bottom: 52px;
                    right: 31px;
                }

                .MobileFrontPlus {
                    display: none;
                }
            }

            .BackOne,
            .BackTwo,
            .BackThree {
                backface-visibility: hidden;
                background-image: url("../../../asset/img/Culture/TeamBackOne.png");
                transform: rotateY(180deg);
                display: flex;
                flex-direction: column;
                padding-top: 89px;
                text-align: left;
                align-items: center;

                span {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 160%;
                    padding: 0 10px;
                }

                .FrontDelete {
                    width: 45px;
                    height: 45px;
                    position: absolute;
                    bottom: 52px;
                    right: 31px;
                }

                .MobileFrontDelete {
                    display: none;
                }
            }

            .BackOne {
                background-image: url("../../../asset/img/Culture/TeamBackOne.png");
                white-space: pre-line;
            }

            .BackTwo {
                background-image: url("../../../asset/img/Culture/TeamBackTwo.png");
                white-space: pre-line;
            }

            .BackThree {
                background-image: url("../../../asset/img/Culture/TeamBackThree.png");
                white-space: pre-line;
            }
        }

        .card:last-child {
            margin-right: 0;
        }
    }

    .Bottom {
        width: 100vw;
        height: 100vh;
    }
}

@include laptop {
    .TeamCulture {
        .TitleBox {
            .TeamTitle {
                margin-bottom: 40px;
            }

            .ActiveTitle {
                font-size: 35px;
                margin-bottom: 20px;
            }
        }
    }
}

@include tablet {
    .TeamCulture {
        height: auto;
        padding-bottom: 100px;
        padding-top: 50px;

        .TitleBox {

            .ActiveTitle {
                font-size: 3.5vw;
            }
        }

        .Top {
            .card {
                width: 230px;

                .FrontOne,
                .FrontTwo,
                .FrontThree {
                    span {
                        font-size: 24px;
                    }
                }

                .FrontOne {
                    background-image: url("../../../asset/img/Culture/TabletCultureOne.svg");
                }

                .FrontTwo {
                    background-image: url("../../../asset/img/Culture/TabletCultureTwo.svg");
                }

                .FrontThree {
                    background-image: url("../../../asset/img/Culture/TabletCultureThree.svg");
                }

                .BackOne {
                    background-image: url("../../../asset/img/Culture/TabletCultureOne.svg");
                }

                .BackTwo {
                    background-image: url("../../../asset/img/Culture/TabletCultureTwo.svg");
                }

                .BackThree {
                    background-image: url("../../../asset/img/Culture/TabletCultureThree.svg");
                }
            }

            .card:last-child {
                width: 235px;
            }
        }
    }
}

@include mobile {
    .TeamCulture {
        margin-top: 150px;

        .TitleBox {
            .TeamTitle {
                font-size: 15px;
                margin-bottom: 20px;
            }

            .ActiveTitle {
                font-size: 4.5vw;
                margin-bottom: 20px;
            }
        }

        .Top {
            flex-direction: column;

            .card {
                width: 232px;
                height: 266px;
                margin-right: 0;
                margin-bottom: 29px;

                .FrontOne {
                    background-image: url("../../../asset/img/Culture/MobileBackOne.svg");
                }

                .FrontTwo {
                    background-image: url("../../../asset/img/Culture/MobileBackTwo.svg");
                }

                .FrontThree {
                    background-image: url("../../../asset/img/Culture/MobileBackThree.svg");
                }

                .FrontOne,
                .FrontTwo,
                .FrontThree {
                    span {
                        font-size: 25px;
                        font-weight: bold;
                    }

                    .FrontPlus {
                        display: none;
                    }

                    .MobileFrontPlus {
                        width: 35px;
                        height: 35px;
                        position: absolute;
                        bottom: 26px;
                        display: flex;
                        right: 26px;
                    }
                }

                .BackOne,
                .BackTwo,
                .BackThree {
                    padding-top: 50px;

                    span {
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .FrontDelete {
                        display: none;
                    }

                    .MobileFrontDelete {
                        width: 35px;
                        height: 35px;
                        position: absolute;
                        display: flex;
                        bottom: 26px;
                        right: 26px;
                    }
                }

                .BackOne {
                    background-image: url("../../../asset/img/Culture/MobileBackOne.svg");
                }

                .BackTwo {
                    background-image: url("../../../asset/img/Culture/MobileBackTwo.svg");
                }

                .BackThree {
                    background-image: url("../../../asset/img/Culture/MobileBackThree.svg");
                }
            }

            .card:last-child {
                margin-bottom: 0;
            }
        }
    }
}