@import "./variables.scss";

@mixin mobile {
    @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-tablet - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-laptop - 1px}) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: #{$breakpoint-laptop}) and (max-width: #{$breakpoint-desktop - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$breakpoint-desktop}) {
        @content;
    }
}

// flex

@mixin flexJustifyCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@mixin flexAlignCenter {
    display: flex;
    align-items: center;
    flex-direction: column;
}

// footer

@mixin FamilyBtnDown{
    position: relative;
    width: 159px;
    background-color: $footer-font-color;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 6px 12px;
    line-height: 20px;
    font-weight: 400;
    font-size: 12px;

    span {
      width: 100%;
      color: $footer-color;
      cursor: pointer;
      transition: all 0.5s ease;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      width: 100%;

      li {
        color: $familysite-font-color;
        cursor: pointer;
        margin-bottom: 5px;
        line-height: 166.667%;
        padding: 5px 0;

        &:hover {
          font-weight: 600;
        }
      }

      a:not(:last-child) {
        li {
          border-bottom: 1px solid $familysite-li-border-color;
        }
      }
    }
}