* {
  margin: 0 auto;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
}
#Back_color {
  background-color: #ffffff;
  height: 969px;
  width: 100%;
  justify-content: center;
}
header {
  text-align: center;
  padding-top: 100px;
}
a {
  color: blue;
}
p {
  line-height: 1.4rem;
  font-size: 14px;
  padding-top: 10px;
  color: #000;
}

ul {
  padding-top: 10px;
  font-size: 14px;
}

li {
  list-style: decimal inside;
  font-size: 14px;
  padding: 4px 0 4px 0;
  line-height: 1.4rem;
}

h5 {
  font-size: 15px;
  color: blue;
  padding: 15px 0 15px 0;
  font-weight: 500;
}
h1 {
  font-weight: 500;
  font-size: 2em;
  color: #000;
}

#container {
  height: 600px;
  margin-top: 40px;
  margin-bottom: 25px;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  background-color: #ffff;
  overflow-x: hidden;
  overflow-y: auto;
}
.content1 {
  width: 80%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding_p {
  padding-top: 15px;
}

.color_red {
  color: red;
}
.content6 h3 {
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 0;
}
.font {
  font-weight: 500;
  color: #000;
}
.images {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
