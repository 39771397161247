.EditDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 200px 20%;

  .BackImg {
    position: absolute;
    width: 100vw;
  }

  .NoticeBoard {
    width: 100vw;
    display: flex;
    flex-direction: column;
    z-index: 2;

    .Title {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 89px;
    }

    .Option {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 53px;
    }

    .Board {
      z-index: 1;
      border-collapse: separate;
      border-spacing: 0px 10px;

      .Header {
        height: 50px;
        text-align: left;
        border-bottom: 1px solid #fff;

        .Number {
          width: 92px;
          text-align: center;
        }

        .WriteDay {
          width: 167px;
        }

        .Name {
          width: 123px;
        }
      }

      .Data {
        background-color: #313131;

        .Mabe {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 23px;
          text-wrap: nowrap;

          div {
            .Edit {
              width: 55px;
              height: 27px;
              border-radius: 10px;
              background-color: #fff;
              font-size: 17px;
              font-weight: bold;
              color: #5e793b;
              margin-right: 16px;
            }

            .Delete {
              width: 55px;
              height: 27px;
              border-radius: 10px;
              background-color: #ff5b5b;
              font-size: 17px;
              font-weight: bold;
              color: #fff;
            }
          }
        }

        .Number {
          text-align: center;
        }

        td {
          text-wrap: nowrap;
          height: 40px;
        }
      }

      td {
        border: none;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .EditDelete {
    padding: 200px 5%;
  }
}
