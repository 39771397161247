@import "../../mixin";

.FourchainsClass {
    background-color: #EAEBED;
    display: flex;
    flex-direction: column;
    padding: 185px 260px;

    .Fourchains {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .ShortName {
            font-size: 20px;
            color: #101010;
            margin-bottom: 60px;
        }

        .CenterName {
            font-size: 50px;
            font-weight: bold;
            color: #101010;
            margin-bottom: 60px;
        }

        .DevelopName {
            margin-top: 100px;
            margin-bottom: 60px;
            color: #003E7E;
            font-size: 40px;
            font-weight: bold;
        }

        .Develop {
            display: flex;
            flex-direction: column;

            .Bold {
                font-size: 20px;
                color: #003E7E;
                margin-bottom: 10px;
                font-weight: 500;

                &.weightBold {
                    font-weight: bold;
                }
            }



            .Light {
                font-size: 20px;
                color: #101010;
                margin-bottom: 35px;
                line-height: 170%;
            }
        }

        .DevelopImg {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            margin-bottom: 88px;
            align-items: center;

            .TextDevelop {
                width: 100%;
                font-weight: bold;
                font-size: 40px;
                margin-bottom: 30px;
                text-align: center;
                color: #101010;
            }

            .SoDetailDevelop {
                margin-top: 150px;
                font-size: 30px;
                font-weight: bold;
                padding: 21px 60px;
                border-radius: 10px;
                color: #6a9bfe;
                border: 1px solid #6a9bfe;
                border-radius: 10px;
            }

            .SoDetailDevelop:hover {
                background-color: #6a9bfe;
                color: #fff;
            }
        }
    }
}

@include tablet {
    .FourchainsClass {
        padding: 185px 110px;

        .Fourchains {
            img {
                width: 100%;
            }

            .Develop {

                .Bold {
                    font-size: 23px;
                }

                .Light {
                    font-size: 16px;
                }
            }

            .DevelopImg {
                img {
                    width: 100%;
                }

                .SoDetailDevelop {
                    margin-top: 150px;
                    font-size: 1.8rem;
                }
            }


        }
    }
}

@include mobile {
    .FourchainsClass {
        padding: 110px 30px;

        .Fourchains {
            .ShortName {
                margin-bottom: 40px;
            }

            .CenterName {
                font-size: 30px;
                margin-bottom: 40px;
            }

            img {
                width: 100%;
            }

            .DevelopName {
                margin-top: 50px;
                font-size: 25px;
                margin-bottom: 60px;
            }

            .Develop {

                .Bold {
                    font-size: 20px;
                }

                .Light {
                    font-size: 14px;
                }
            }

            .DevelopImg {
                margin-top: 60px;

                .TextDevelop {
                    font-size: 30px;
                }

                .SoDetailDevelop {
                    font-size: 1.45rem;
                }
            }


        }
    }
}