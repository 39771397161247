@import "../../mixin";

.About {
    width: 100vw;
    height: 100vh;
    background-image: url("../../../asset/img/Main/AboutCompany.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;

    .TabletBlur {
        display: none;
    }

    .Blur {

        .BlueHead {
            font-size: 20px;
            text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30);
            // margin-bottom: 30px;
            line-height: 260%;
            white-space: pre-line;

            .BlueBtm {
                margin-top: 80px;
                white-space: pre-line;
            }
        }
    }
}

@include tablet {
    .About {
        height: auto;
        padding-top: 160px;
        padding-bottom: 200px;
        background-position: center center;
        background-size: 100%;

        .Blur {
            display: none;
        }

        .TabletBlur {
            display: flex;

            .BlueHead {
                font-size: 3.2vw;
                zoom: 50%;
                text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30);
                line-height: 230%;
                width: 100vw;
            }

            .BlurHead {
                font-size: 20px;
                text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30);
                line-height: 230%;
            }

            .BlurBtm {
                font-size: 22px;
                text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30);
                line-height: 230%;
            }

            .BlueBtm {
                margin-top: 40px;
            }
        }
    }
}

@include mobile {
    .About {
        height: auto;
        padding-top: 60px;
        padding-bottom: 80px;
        background-position: center center;
        background-size: 100%;

        .Blur {
            display: none;
        }

        .TabletBlur {
            display: flex;

            .BlueHead {
                font-size: 4vw;
                zoom: 50%;
                text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30);
                line-height: 230%;
                // width: 100vw;
                white-space: pre-line;
            }

            .BlurHead {
                font-size: 9px;
                text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30);
                line-height: 230%;
            }

            .BlurBtm {
                font-size: 9px;
                text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30);
                line-height: 180%;
            }

            .BlueBtm {
                margin-top: 15px;
            }
        }
    }
}