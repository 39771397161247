@import "../../mixin";

.PartnerCompany {
  height: 421px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .TitleWrapper {
    margin-bottom: 89px;
    align-items: center;
    justify-content: center;
    display: flex;

    .PartnerTitle {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      padding: 0 10px;
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .SlideContainer {
      overflow: hidden;
      width: 100%;
      height: 147px;

      .SlideWrapper {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        width: 100%;
        height: 147px;
        margin: 0 auto;
        box-sizing: border-box;
      }

      .slide {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;

        &.original {
          animation: 130s linear infinite normal none running infiniteAnimation1;
        }

        &.clone {
          animation: 130s linear infinite infiniteAnimation2;
        }

        li {
          margin-right: 30px;
          z-index: 2;

          &.ImgWrapper {
            height: 100%;
            list-style: none;
          }
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .PartnerCompany {
    .TitleWrapper {
      .PartnerTitle {
        font-size: 1.8rem;
      }
    }
  }
}

@include mobile{
  .PartnerCompany {
    height: 421px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
  
      .SlideContainer {
        overflow: hidden;
        width: 100%;
        height: 147px;
  
        .SlideWrapper {
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          width: 100%;
          height: 147px;
          margin: 0 auto;
          box-sizing: border-box;
        }
  
        .slide {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          position: relative;
  
          &.original {
            animation: 130s linear infinite normal none running infiniteAnimation1;
          }
  
          &.clone {
            animation: 130s linear infinite infiniteAnimation2;
          }
  
          li {
            margin-right: 30px;
            z-index: 2;
  
            &.ImgWrapper {
              height: 100%;
              width: auto; 
            }
            img {
              width: 95vw;  
              height: auto;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

@keyframes infiniteAnimation1 {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-100%);
  }

  50.1% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes infiniteAnimation2 {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-200%);
  }
}