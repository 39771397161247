@import "../../mixin";

$main-background-color: #252525;
$main-slogan-color: white;
$main-slogan-slide-active: #d9d9d9;
$main-slogan-bg: url("../../../asset/img/Main/MainSlogan/MainSloganBgDesktop.svg");

.MainSlogan {
  width: 100vw;
  height: 100vh;
  background-color: $main-background-color;
  background-image: $main-slogan-bg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  @include flexJustifyCenter;
  padding-left: 260px;

  div {
    color: $main-slogan-color;
  }

  .MainContainer {
    white-space: pre-line;

    .MainSloganTextWrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 33px;
    }

    .MainSloganTitle {
      font-size: 40px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 29px;
    }

    .MainSloganTitleSecond {
      font-size: 40px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 70px;
    }

    .SlideBtnWrapper {
      display: flex;
      z-index: 2;
    }

    .SlideBtn {
      width: 31px;
      height: 7px;
      cursor: pointer;
      background-color: rgba(217, 217, 217, 0.3);
      border-radius: 50px;
      margin-right: 12px;
    }

    .ActiveSlideBtn {
      background-color: $main-slogan-slide-active;
    }
  }

  .start {
    opacity: 0;
    transform: translateX(-100px);
  }

  .end {
    transition: opacity 1s ease, transform 1s ease;
    opacity: 1;
    transform: translateX(0);
  }
}

@include tablet {
  .MainSlogan {
    height: auto;
    padding-top: 300px;
    padding-bottom: 250px;
    padding-left: 0;
    align-items: center;

    .MainContainer {
      width: 586.84px;
    }
  }
}

@include mobile {
  .MainSlogan {
    margin-top: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
    height: auto;
    background-image: none;
    padding-left: 40px;

    .MainSloganTitle {
      font-size: 13vw;
      zoom: 50%;
    }

    .MainSloganTitleSecond {
      font-size: 11vw;
      zoom: 50%;
      margin-bottom: 50px;
    }

    .MainSloganContent {
      font-size: 5vw;
      zoom: 50%;
    }
  }
}