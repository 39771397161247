@import "../../mixin";

.Department {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .MainTitle {
        font-size: 20px;
        margin-bottom: 60px;
    }

    .Title {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 30px;
        white-space: pre-line;
    }

    .MainSlide {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .SlideSBox {
            width: 1000px;

            .Box {
                height: 456px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 60px;

                .OneBox {
                    margin-right: 24px;
                    margin-left: 40px;
                }

                .OnesBox {
                    margin-left: 40px;
                }

                .OneBox:last-child {
                    margin-right: 0px;
                    margin-left: 0px;
                }
            }
        }
    }

    .custom-arrow {
        position: absolute;
        top: 44%;
        transform: translateY(-50%);
        background-color: rgba(255, 0, 0, 0);
        border: none;
        cursor: pointer;
    }

    .custom-arrow-prev {
        left: 10px;
        z-index: 999;
    }

    .custom-arrow-next {
        right: 10px;
    }
}

.carousel .control-dots .dot.selected {
    background-color: #fff;
    border: none;
}

.carousel .control-dots .dot {
    background-color: rgba(255, 0, 0, 0);
    border: 3px solid #fff;
    opacity: 10;
    width: 25px;
    height: 13px;
    border-radius: 50px;
}

.carousel .slide {
    list-style: none;
}

.MainDepartment {
    width: 448px;
    height: 378px;
    border-radius: 10px;

    .DeBox {
        border-radius: 10px;

        .Top {
            height: 213px;
        }

        .Btm {
            background-color: #EAEBED;
            height: 165px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;

            .MainName {
                font-size: 28px;
                font-weight: bold;
                color: #101010;
                margin-bottom: 20px;
            }

            .SubName {
                font-size: 18px;
                color: #101010;
            }
        }
    }

    .RnD {
        background-image: url("../../../asset/img/Culture/RnD.svg");
    }

    .BlockChain {
        background-image: url("../../../asset/img/Culture/BlockChain.svg");
    }

    .Development {
        background-image: url("../../../asset/img/Culture/Development.svg");
    }

    .Marketing {
        background-image: url("../../../asset/img/Culture/Marketing.svg");
    }

    .Management {
        background-image: url("../../../asset/img/Culture/Management.svg");
    }
}

@include laptop {
    .Department {
        .MainTitle {
            margin-bottom: 40px;
        }

        .Title {
            font-size: 35px;
            margin-bottom: 20px;
        }

        .MainSlide {
            .SlideSBox {
                .Box {
                    height: 400px;
                    margin-bottom: 50px;
                }
            }
        }
    }

    .MainDepartment {
        height: 350px;

        .DeBox {
            .Top {
                height: 220px;
            }

            .Btm {
                height: 140px;

                .MainName {
                    font-size: 24px;
                    margin-bottom: 0px;
                }

                .SubName {
                    font-size: 16px;
                }
            }
        }
    }
}

@include tablet {
    .Department {
        height: auto;
        padding-bottom: 100px;
        padding-top: 50px;

        .Title {
            font-size: 3.5vw;
        }

        .MainSlide {
            .SlideSBox {
                width: 700px;
            }
        }
    }

    .MainDepartment {
        width: 300px;


        .DeBox {
            .Btm {
                height: 130px;

                .MainName {
                    margin-bottom: 0px;
                    font-size: 20px;
                }

                .SubName {
                    font-size: 13px;
                }
            }
        }
    }
}

@include mobile {

    .Department {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 47px;

        .MainTitle {
            font-size: 1.25rem;
            margin-bottom: 60px;
        }

        .Title {
            font-size: 4.2vw;
            font-weight: bold;
            margin-bottom: 30px;
        }

        .MainSlide {
            .SlideSBox {
                width: 370px;

                .Box {
                    flex-direction: column;
                    height: 600px;
                    margin-bottom: 40px;

                    .OneBox {
                        margin-right: 0px;
                        margin-left: 0px;
                    }
                }
            }
        }

        .custom-arrow-prev {
            display: none;
        }

        .custom-arrow-next {
            display: none;
        }
    }

    .MainDepartment {
        width: 300px;
        height: 300px;

        .DeBox {
            .Top {
                height: 160px;
            }

            .Btm {
                height: 120px;
                padding: 5px 10px;

                .MainName {
                    margin-bottom: 0px;
                    font-size: 24px;
                }

                .SubName {
                    font-size: 14px;
                }
            }
        }
    }
}