@import "../../mixin";

.Sustainable {
    background-color: #EAEBED;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 100px;

    .SusImgBox {
        margin-bottom: 200px;

        img {
            width: 100%;
        }
    }

    .SusText {
        margin-left: 179px;
        text-align: left;
        width: 80%;
        // letter-spacing: 2px;


        .MainText {
            font-size: 40px;
            color: #252525;
            margin-bottom: 100px;
        }

        .SubText {
            line-height: 240%;
            font-size: 17px;
            color: #252525;
            white-space: pre-line;
        }

        .Orner {
            margin-top: 100px;
            font-size: 17px;
            color: #252525;
            text-align: left;
            margin-bottom: 230px;
        }
    }
}

@include laptop {
    .Sustainable {
        .SusText {
            font-size: 18px;
        }
    }
}

@include tablet {
    .Sustainable {

        .SusText {
            margin-left: 100px;

            .MainText {
                font-size: 30px;
                margin-bottom: 25px;
            }

            .SubText {
                line-height: 220%;
                font-size: 15px;
            }
        }
    }
}

@include mobile {
    .Sustainable {
        .SusImgBox {
            margin-bottom: 52px;
        }

        .SusText {
            margin-left: 50px;

            .MainText {
                font-size: 10vw;
                zoom: 50%;
                margin-bottom: 25px;
            }

            .SubText {
                font-size: 5vw;
                zoom: 50%;
                line-height: 220%;
            }

            .Orner {
                font-size: 5vw;
                zoom: 50%;
            }
        }

        .MainTop {
            margin-top: 97px;
            padding-right: 32px;
        }
    }
}